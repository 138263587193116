import React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/seo';
import { Heading, Center, Grid, GridItem, Button, Text, VStack, Flex, Image, Icon, Box } from '@chakra-ui/react';
import { Link } from 'gatsby';
import { motion } from 'framer-motion';
import { VscArrowRight } from 'react-icons/vsc';

const MotionImage = motion(Image);

const IndexPage = ({ location }) => {
  return (
    <Layout location={location}>
      <SEO title="Home" />

      <Flex
        position="absolute"
        top="0"
        h={{ base: '50px', md: '100px' }}
        w="100%"
        align="center"
        p={{ base: '1rem', md: '2rem' }}
        justify="space-between"
        zIndex="1"
        color="white"
      >
        <Box>
          <Image src="/images/white-logo.png" h={{ base: '45px', md: '65px' }} />
        </Box>
        <Heading fontSize={{ base: 'md', lg: '30px' }}>General Construction</Heading>
      </Flex>
      <Grid
        templateColumns={{ lg: 'repeat(2, 1fr)' }}
        gap="0"
        h={{ base: '900px', md: '100vh' }}
        bg="primary"
        color="white"
      >
        <GridItem w="100%" h="100%" pos="relative" bg="primary">
          <MotionImage
            src="/images/res-slider-1.jpg"
            h="100%"
            w="100%"
            position="absolute"
            objectFit="cover"
            objectPosition="center"
            opacity="0.5"
            whileHover={{ opacity: 0.7 }}
          />
          <Center h="100%">
            <VStack
              maxW={{ base: '90vw', lg: '500px' }}
              px={{ base: '1rem' }}
              textAlign="center"
              fontSize={{ base: '6px', lg: '10px' }}
              zIndex="1"
            >
              <Image src="/icons/housing.svg" filter="brightness(100)" boxSize={{ base: '40px', md: '60px' }} />
              <Heading fontSize={{ base: '25px', md: '38px' }}>Residential</Heading>
              <Text fontSize={{ base: 'md', md: '22px' }} fontWeight="bold">
                The style and design of your residential project is our priority. We incorporate the best materials,
                project management practices and solutions to help make your final vision a reality.
              </Text>
              <Link to="/residential">
                <Button
                  size="lg"
                  mt="1rem"
                  bg="white"
                  color="primary"
                  _hover={{ bg: 'secondary', color: 'white' }}
                  transform={{ base: 'scale(0.8)', md: 'none' }}
                >
                  <Flex
                    boxSize="30px"
                    mr="1rem"
                    borderRadius="100%"
                    background="primary"
                    align="center"
                    justify="center"
                  >
                    <Icon as={VscArrowRight} fill="white" />
                  </Flex>
                  Visit Residential
                </Button>
              </Link>
            </VStack>
          </Center>
        </GridItem>
        <GridItem w="100%" h="100%" pos="relative">
          <MotionImage
            src="/images/condo-buildings-bg.jpg"
            h="100%"
            w="100%"
            position="absolute"
            objectFit="cover"
            objectPosition="top"
            opacity="0.5"
            whileHover={{ opacity: 0.7 }}
            bg="primary"
          />
          <Center h="100%">
            <VStack
              maxW={{ base: '90vw', lg: '500px' }}
              px={{ base: '1rem' }}
              textAlign="center"
              fontSize={{ base: '6px', lg: '10px' }}
              zIndex="1"
            >
              <Image src="/icons/condo.svg" filter="brightness(100)" boxSize={{ base: '40px', md: '60px' }} />
              <Heading fontSize={{ base: '25px', md: '38px' }}>Commercial</Heading>
              <Text fontSize={{ base: 'md', md: '22px' }} fontWeight="bold">
                Working on challenging commercial projects is our specialty. Our team of highly skilled project managers
                and craftsmen are equipped with the resources and knowledge to manage the design and construction of
                your commercial project.
              </Text>
              <Link to="/commercial">
                <Button
                  size="lg"
                  mt="1rem"
                  bg="white"
                  color="primary"
                  _hover={{ bg: 'secondary', color: 'white' }}
                  transform={{ base: 'scale(0.8)', md: 'none' }}
                >
                  <Flex
                    boxSize="30px"
                    mr="1rem"
                    borderRadius="100%"
                    background="primary"
                    align="center"
                    justify="center"
                  >
                    <Icon as={VscArrowRight} fill="white" />
                  </Flex>
                  Visit Commercial
                </Button>
              </Link>
            </VStack>
          </Center>
        </GridItem>
      </Grid>
    </Layout>
  );
};

export default IndexPage;
